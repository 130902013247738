<template>
  <div
    v-if="createdFinished"
    class="UserProfileAssignments column"
  >
    <!-- ASSIGNMENT STATUS NAVIGATION -->
    <div class="UserProfileAssignments__navigation row row--align-end">
      <!-- ALL -->
      <div
        @click="navigate('all')"
        :class="[
          'column column--width-auto mr-25 clickable',
          { 'UserProfileAssignments__navigation--active': currentSort === 'all' }
        ]"
      >
        <div class="row row--align-center mb-8">
          <label :class="['fs-14 fw-med', { 'fc-blue': currentSort === 'all' }]">
            All
            <span class="fc-light fs-14">
              {{
                assignments.all
                  ? assignments.all.count
                  : 0
              }}
            </span>
          </label>
        </div>
      </div>

      <!-- VERIFIED -->
      <div
        @click="navigate('verified')"
        :class="[
          'column column--width-auto mr-25 clickable',
          { 'UserProfileAssignments__navigation--active': currentSort === 'verified' }
        ]"
      >
        <div class="row row--align-center mb-8">
          <i class="fa fa-oval fa-10 fc-green mr-11" />
          <label :class="['fs-14 fw-med', { 'fc-blue': currentSort === 'verified' }]">
            Verified
            <span class="fc-light fs-14">
              {{
                assignments.verified
                  ? assignments.verified.count
                  : 0
              }}
            </span>
          </label>
        </div>
      </div>

      <!-- IN PROGRESS -->
      <div
        @click="navigate('pending')"
        :class="[
          'column column--width-auto mr-25 clickable',
          { 'UserProfileAssignments__navigation--active': currentSort === 'pending' }
        ]"
      >
        <div class="row row--align-center mb-8">
          <i class="fa fa-oval fa-10 fc-yellow mr-11" />
          <label :class="['fs-14 fw-med', { 'fc-blue': currentSort === 'pending' }]">
            In Progress
            <span class="fc-light fs-14">
              {{
                assignments.pending
                  ? assignments.pending.count
                  : 0
              }}
            </span>
          </label>
        </div>
      </div>

      <!-- RELEASED -->
      <div
        @click="navigate('released')"
        :class="[
          'column column--width-auto mr-25 clickable',
          { 'UserProfileAssignments__navigation--active': currentSort === 'released' }
        ]"
      >
        <div class="row row--align-center mb-8">
          <i class="fa fa-oval fa-10 fc-red mr-11" />
          <label :class="['fs-14 fw-med', { 'fc-blue': currentSort === 'released' }]">
            Released
            <span class="fc-light fs-14">
              {{
                assignments.released
                  ? assignments.released.count
                  : 0
              }}
            </span>
          </label>
        </div>
      </div>

      <base-debtor-search
        v-if="pagePOV === 'client'"
        @selected="searchByUser"
        class="ml-auto mb-6"
        :data-cy="'assignments-debtor-search-input'"
        :debtor-i-d-from-query="searchUserID"
        :label="false"
        :placeholder="'Search debtor'"
      />
      <base-client-search
        v-if="pagePOV === 'debtor'"
        @selected="searchByUser"
        class="ml-auto mb-6"
        :client-i-d-from-query="searchUserID"
        :label="false"
        :placeholder="'Search client'"
      />
    </div>

    <!-- ASSIGNMENTS -->
    <div
      v-if="assignments[currentSort]"
      class="UserProfileAssignments__assignments column"
    >
      <div
        v-for="(assignment, index) in assignments[currentSort].rows"
        :key="index"
        class="column"
      >
        <!-- ASSIGNMENT TOP DISPLAY -->
        <div
          @click="expandAssignment(index)"
          class="row row--align-center clickable mt-16 mb-16"
        >
          <i
            :class="['fa fa-oval fa-10 mr-11', {
              'fc-red': assignment.status === 'released',
              'fc-green': assignment.status === 'verified',
              'fc-yellow': assignment.status === 'pending',
            }]"
            :data-cy="`assignment-dot-${index}`"
          />
          <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
          <label
            v-if="pagePOV === 'client'"
            class="fs-15 uppercase"
            :data-cy="`assignment-name-${index}`"
          >
            {{ filterAlgoliaDebtorName(assignment.debtor.name) }}
          </label>
          <label
            v-if="pagePOV === 'debtor'"
            class="fs-15"
          >
            {{ assignment.client.name }}
          </label>
        </div>

        <!-- ASSIGNMENT BOTTOM DISPLAY -->
        <transition
          mode="out-in"
          name="Transition__opacity-fade"
        >
          <div
            v-if="assignment.expanded && assignment.history"
            class="UserProfileAssignments__modal"
          >
            <template v-if="assignment.history.length">
              <div class="UserProfileAssignments__modal-content">
                <!-- ASSIGNMENT HISTORY -->
                <div
                  v-for="(history, historyIndex) in assignment.history"
                  :key="historyIndex"
                  class="row row--align-center row--justify-between mb-3"
                >
                  <div class="row row--align-center">
                    <i
                      :class="['fa fa-oval fa-7 mr-11', {
                        'fc-red': history.status === 'released',
                        'fc-green': history.status === 'verified',
                        'fc-yellow': history.status === 'pending',
                      }]"
                      :data-cy="`assignment-history-dot-${index}-${historyIndex}`"
                    />
                    <label
                      :class="['UserProfileAssignments__modal-content-status-lbl fs-13', {
                        'uppercase': ['released', 'verified', 'pending'].includes(history.status)
                      }]"
                      :data-cy="`assignment-history-status-${index}-${historyIndex}`"
                    >
                      {{ history.status }}
                    </label>
                  </div>
                  <!-- Automated NOA email updates are apart of an assignment's "pending" update -->
                  <time
                    v-if="history.email_type !== 'noa'"
                    class="fc-mid fs-12 no-wrap"
                    :datetime="history.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
                  >
                    {{ history.created_at | datetime('h:mm A MMM D, YYYY') }}
                  </time>
                </div>
              </div>

              <hr class="Divider">

              <!-- ASSIGNMENT FOOTER -->
              <div class="UserProfileAssignments__footer-content row row--align-center bg">
                <transition
                  mode="out-in"
                  name="Transition__fade"
                >
                  <!-- Release letter + actions -->
                  <template v-if="assignment.status === 'released' && !emailing">
                    <div class="row row--align-center">
                      <label class="fs-14 no-wrap">Release Letter</label>
                      <div class="row row--align-center row--justify-end">
                        <button
                          @click="viewReleaseLetter(assignment)"
                          class="mr-20"
                          :data-cy="`assignment-release-letter-${index}`"
                        >
                          <i class="fa fa-view fa-17 fa--none fc-light" />
                        </button>
                        <button @click="showSendReleaseLetterFlow">
                          <i class="fa fa-mail fa-17 fa--none fc-light" />
                        </button>
                      </div>
                    </div>
                  </template>
                  <!-- Depending on the status of the assignment: -->
                  <!-- Send the release letter on a released assignment -->
                  <!-- Resend NOA Email on verified invoice -->
                  <template v-else-if="emailing">
                    <div class="row row--align-end">
                      <base-input
                        v-model.trim="email.value"
                        class="mr-10 width-100"
                        :instructions="assignment.status === 'released'
                          ? 'Must be a email (@, domain name, ending required)'
                          : instructions.NOA_EMAILS_INSTRUCTION
                        "
                        :label="true"
                        :label-for="'email'"
                        :valid="email.valid"
                      >
                        Email
                      </base-input>
                      <div :class="['row row--width-auto', { 'mb-18': !email.valid }]">
                        <base-button
                          @click="assignment.status === 'released'
                            ? sendReleaseLetter(assignment)
                            : resendNOA(assignment, index)
                          "
                          @keydown.enter="assignment.status === 'released'
                            ? sendReleaseLetter(assignment)
                            : resendNOA(assignment, index)
                          "
                          class="bg-blue fc-white mr-10"
                          :disabled="buttonsDisabled"
                        >
                          Submit
                        </base-button>
                        <base-button
                          @click="cancelEmailing"
                          @keydown.enter="cancelEmailing"
                          class="bg-light fc-white"
                          :disabled="buttonsDisabled"
                        >
                          Cancel
                        </base-button>
                      </div>
                    </div>
                  </template>
                  <!-- DEFAULT VIEW FOR NON-RELEASED ASSIGNMENT -->
                  <!-- Contains "release" and "email" button options -->
                  <template v-else>
                    <div class="row">
                      <base-button
                        @click="releaseAssignment(assignment)"
                        class="bg-red fc-white"
                        :disabled="buttonsDisabled"
                      >
                        Release Company
                      </base-button>

                      <base-button
                        @click="showResendNOAFlow(assignment)"
                        class="bg-blue fc-white ml-10"
                      >
                        Email NOA
                      </base-button>
                    </div>
                  </template>
                </transition>
              </div>
            </template>

            <!-- No history data for the assignment -->
            <template v-else>
              <label class="row ml-30 mt-16 mb-16">
                There is no historical data for this assignment
              </label>
            </template>
          </div>
        </transition>
      </div>
    </div>

    <base-button
      v-if="this.pagePOV === 'client' && searchUserID && (!assignments.all || !assignments.all.rows.length)"
      @click="releasing = true"
      @keydown.enter="releasing = true"
      class="bg-blue fc-white ml-30 mb-20"
      :data-cy="'create-release-letter-btn'"
    >
      Create Release Letter
    </base-button>

    <base-pagination
      @paginate="page = $event"
      class="mb-30"
      :item-count="
        assignments[currentSort]
          ? assignments[currentSort].count
          : 0
      "
      :page="page"
      :request-limit="25"
    />

    <!-- CONFIRM RELEASE MODAL -->
    <base-item-delete
      v-if="releasing"
      @cancel="releasing = false"
      @delete="createReleaseAssignment"
      :confirm-text="'Yes, Release Them'"
      :delete-text="`Are you sure you want to create a release letter? It will create
        a new assignment and set it to released`"
    />
  </div>
</template>

<script>
// Helpers
import {
  ClientDebtorAssignment,
  Invoice,
} from '../utils/api'
import { sanitizeAlgoliaNames } from '../utils/helpers'
// Components
import BaseButton from './base-button.vue'
import BaseClientSearch from './base-client-search.vue'
import BaseDebtorSearch from './base-debtor-search.vue'
import BaseInput from './base-input.vue'
import BaseItemDelete from './base-item-delete.vue'
import BasePagination from './base-pagination.vue'
// Mixins
import { ValidationMixin } from '../utils/validation-mixin'

export default {
  name: 'UserProfileAssignments',

  components: {
    BaseButton,
    BaseClientSearch,
    BaseDebtorSearch,
    BaseInput,
    BaseItemDelete,
    BasePagination
  },

  mixins: [ValidationMixin],

  props: {
    pagePOV: {
      type: String,
      required: true,
    },
  },

  async created () {
    // Query string? Compare variables with component data; if different, perform search
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)

    // Check for search variables, update component data
    // If we don't use an if...else for this, the change in `this.searchUserID` will trigger
    // the watcher for the same property to fire getAssignments, resulting in a double call
    if (queryString) {
      if (queryParams.has('ID')) {
        if (queryParams.get('ID').toLowerCase() !== this.searchUserID) {
          this.searchUserID = queryParams.get('ID')
        }
      }
    }
    else {
      await this.getAssignments()
    }
    this.createdFinished = true
  },

  data () {
    return {
      assignments: {
        // Each property contains `count` and `rows`
        all: null,
        pending: null,
        released: null,
        verified: null,
      },
      buttonsDisabled: false,
      createdFinished: false,
      currentSort: 'all',
      email: {
        valid: true,
        value: null,
      },
      emailing: false,
      page: 1,
      releasing: false,
      searchUserID: null
    }
  },

  computed: {
    user () {
      if (this.pagePOV === 'client') {
        return this.$store.getters.client
      }
      return this.$store.getters.debtor
    },
  },

  watch: {
    async page () {
      await this.assignmentsRequest(this.currentSort)
    },

    async searchUserID () {
      this.page = 1
      await this.getAssignments()
    },
  },

  methods: {
    async assignmentsRequest (status) {
      const assignments = (await ClientDebtorAssignment.queryList({
        client_id: this.pagePOV === 'client' ? this.user.id : this.searchUserID,
        debtor_id: this.pagePOV === 'debtor' ? this.user.id : this.searchUserID,
        status: status === 'all' ? null : status,
        limit: 25,
        offset: this.page === 1 ? 0 : (this.page - 1) * 25,
      })).data
      assignments.rows.forEach(ass => { ass.expanded = false })
      this.$set(this.assignments, status, assignments)
    },

    cancelEmailing () {
      this.emailing = false
      this.$set(this.email, 'valid', true)
      this.$set(this.email, 'value', '')
    },

    collapseAllCurrentSortAssignments () {
      this.assignments[this.currentSort].rows.forEach(assignment => {
        this.$set(assignment, 'expanded', false)
      })
    },

    async createReleaseAssignment () {
      try {
        this.releasing = false
        this.progressStart()
        // creates
        const assignment = (await ClientDebtorAssignment.create({
          client_id: this.user.id,
          debtor_id: this.searchUserID,
          status: 'pending'
        })).data
        // sets to released
        await ClientDebtorAssignment.update({
          id: assignment.id,
          client_id: assignment.client_id,
          debtor_id: assignment.debtor_id,
          status: 'released',
        })
        await this.getAssignments()
        this.progressFinish()
      } catch (error) {
        this.captureSentryEvent(
          'User Assignments "Create Release Letter" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue creating the release letter' })
      }
    },

    async expandAssignment (index) {
      this.emailing = false

      // Reset the email values
      this.$set(this.email, 'valid', true)
      this.$set(this.email, 'value', '')

      // Collapse if already expanded
      if (this.assignments[this.currentSort].rows[index].expanded) {
        this.$set(this.assignments[this.currentSort].rows[index], 'expanded', false)
        return
      }

      this.progressStart()
      this.collapseAllCurrentSortAssignments()
      await this.getAssignmentHistory(this.assignments[this.currentSort].rows[index])
      await this.getAssignmentNOADetails(this.assignments[this.currentSort].rows[index])
      this.assignments[this.currentSort].rows[index].expanded = true
      this.progressFinish()
    },

    // Remove <em></em> from debtor name
    // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1694
    filterAlgoliaDebtorName (name) {
      return sanitizeAlgoliaNames(name)
    },

    async getAssignmentHistory (assignment) {
      try {
        const assignmentHistory = (await ClientDebtorAssignment.history({
          id: assignment.id,
          client_id: assignment.client_id,
          debtor_id: assignment.debtor_id
        })).data.rows
        Object.assign(assignment, { history: assignmentHistory })
      }
      catch (error) {
        this.captureSentryEvent(
          'User Assignments "Get History" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the assignment\'s history' })
      }
    },

    async getAssignmentNOADetails (assignment) {
      try {
        // Non-[pending, verified, released] updates have their own provided history.status
        // So, since it's not possible/easy to include the NOA email updates into the actual
        // assignments' history, we have to get them, configure them with a history.status
        // and then add then in where appropriate to the assignments' history
        // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1829
        const assignmentNOADetails = (await ClientDebtorAssignment.getNOAEmails(assignment.id))
          .data
          .map(email => {
            const history = email
            // Every address needs to be formatted like <address1>, <address2> for wrapping
            const address = email.email
              .replace(/ /g, '')
              .split(',')
              .join(', ')
            if (email.email_type === 'noa') {
              history.status = `NOA was emailed to ${address}`
            } else {
              history.status = `NOA was resent to ${address}`
            }
            return history
          })
        const assignmentHistory = assignment
          .history
          .concat(assignmentNOADetails)
          .sort((a, b) => {
            return (new Date(a.created_at)).getTime() - (new Date(b.created_at)).getTime()
          })
        Object.assign(assignment, { history: assignmentHistory })
      } catch (error) {
        this.captureSentryEvent(
          'User Assignments "Get NOA EMAILS History Details"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the noa emails' })
      }
    },

    async getAssignments () {
      this.progressStart()
      try {
        await this.assignmentsRequest('all')
        await this.assignmentsRequest('pending')
        await this.assignmentsRequest('released')
        await this.assignmentsRequest('verified')
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'User Assignments "Get Dat ASS" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client debtor assignments' })
      }
    },

    navigate(where) {
      // Collapse all currentSort assignments before changing tabs to prevent assignments from
      // being open when navigating back and forth
      this.collapseAllCurrentSortAssignments()
      this.currentSort = where
      this.page = 1
    },

    async releaseAssignment (assignment) {
      this.buttonsDisabled = true
      this.progressStart()

      // Check for approved invoices
      try {
        const results = (await Invoice.queryList({
          client_id: assignment.client_id,
          debtor_id: assignment.debtor_id,
          limit: 1, // Only need 1 to deny the release
          status: 'approved'
        })).data

        // If there are approved invoices do not release
        if (results.count) {
          this.CLog('Cannot release assignment due to approved invoices between this relationship were found')
          this.alertWarning(
            'Assignment cannot be released until all approved invoices in this relationship are paid',
            'Operation requirement'
          )
          this.buttonsDisabled = false
          this.progressFinish()
          return
        }
        try {
          await ClientDebtorAssignment.update({
            id: assignment.id,
            client_id: assignment.client_id,
            debtor_id: assignment.debtor_id,
            status: 'released',
          })
          await this.getAssignments()
          this.progressFinish()
        }
        catch (error) {
          this.captureSentryEvent(
            'User Assignments "Update" Error',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          let message = 'There was an issue updating the assignment'
          if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message
          }
          this.requestFailure({ message })
        }
      }
      catch (error) {
        this.captureSentryEvent(
          'User Assignments "Check For Approved Invoices" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue checking for outstanding invoices' })
      }

      this.buttonsDisabled = false
    },

    async resendNOA (assignment, index) {
      // Index is used to avoid mutating the function param
      if (!this.validation()) return

      this.buttonsDisabled = true

      try {
        this.progressStart()

        await ClientDebtorAssignment.resendNOA({
          id: assignment.id,
          to: this.email.value
        })

        this.emailing = false

        // Due to the time it takes for the history of the assignment to be updated, we're
        // going to artificially update it on the FE
        const address = this.email.value
          .replace(/ /g, '')
          .split(',')
          .join(', ')
        const resent = {
          email_type: 're-send noa',
          status: `NOA was resent to ${address}`,
          created_at: new Date(),
        }
        // We want to avoid mutating the function param, so we need to directly push the
        // new, artifical history to the existing assignment
        // (.push is reactive by default)
        this.assignments[this.currentSort].rows[index].history.push(resent)
        this.requestSuccess({ message: 'The NOA has been sent' })
      }
      catch (error) {
        this.captureSentryEvent(
          'User Assignments "Re-Send NOA" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue resending the NOA' })
      }

      this.buttonsDisabled = false
    },

    searchByUser (user) {
      // User could be null
      if (user) {
        this.searchUserID = user.id
        // If user.id exists, add to query
        window.history.replaceState({}, '', `?ID=${user.id}`)
      }
      else {
        this.searchUserID = null
      }
    },

    async sendReleaseLetter (assignment) {
      if (!this.validation()) return

      this.buttonsDisabled = true
      this.progressStart()

      try {
        await ClientDebtorAssignment.sendAssignmentEmail({
          email: this.email.value,
          id: assignment.id,
        })
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'User Assignments "Send Release Letter" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: `There was an issue sending the document to ${this.email.value}`
        })
      }
      this.buttonsDisabled = false
      this.emailing = false
      this.email.value = ''
    },

    showResendNOAFlow (assignment) {
      if (assignment.debtor && assignment.debtor.noa_emails) {
        this.$set(this.email, 'value', assignment.debtor.noa_emails.join(','))
      }
      this.emailing = true
    },

    showSendReleaseLetterFlow () {
      this.$set(this.email, 'value', this.user.email)
      this.emailing = true
    },

    validation () {
      let valid = true

      if (!this.isValidEmails(this.email.value)) {
        this.$set(this.email, 'valid', false)
        valid = false
      } else {
        this.$set(this.email, 'valid', true)
      }

      return valid
    },

    viewReleaseLetter (assignment) {
      window.open(assignment.release_letter_url)
    },
  },
}
</script>

<style lang="sass">
.UserProfileAssignments

  .BaseUserSearch__input
    border-color: #D8DCE6

  .Divider
    margin-left: rem(22px)

  &__assignments
    margin-top: rem(81px) // Height of navigation (absolute positioned)
    padding: 0 rem(29px) rem(30px)

  &__footer-content
    padding: rem(20px) rem(30px)
    width: 100%

  &__modal
    border-radius: $border-radius
    box-shadow: $modal-shadow
    max-width: rem(600px)
    width: 100%

    .Divider
      margin: 0

  &__footer-content
    padding: rem(20px) rem(30px)
    width: 100%

  &__modal-content
    padding: rem(11px) rem(30px)
    width: 100%

  &__modal-content-status-lbl
    line-height: rem(28px)

  &__navigation
    background-color: rgba($background, .3)
    border-top: rem(1px) solid $borders
    left: 0
    padding: rem(6px) rem(29px) rem(0px) // 29px is width of router-view
    position: absolute
    top: 0

    &--active
      border-bottom: rem(2px) solid $branding
</style>