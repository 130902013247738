var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.createdFinished
    ? _c(
        "div",
        { staticClass: "UserProfileAssignments column" },
        [
          _c(
            "div",
            {
              staticClass:
                "UserProfileAssignments__navigation row row--align-end"
            },
            [
              _c(
                "div",
                {
                  class: [
                    "column column--width-auto mr-25 clickable",
                    {
                      "UserProfileAssignments__navigation--active":
                        _vm.currentSort === "all"
                    }
                  ],
                  on: {
                    click: function($event) {
                      return _vm.navigate("all")
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row row--align-center mb-8" }, [
                    _c(
                      "label",
                      {
                        class: [
                          "fs-14 fw-med",
                          { "fc-blue": _vm.currentSort === "all" }
                        ]
                      },
                      [
                        _vm._v("\n          All\n          "),
                        _c("span", { staticClass: "fc-light fs-14" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.assignments.all
                                  ? _vm.assignments.all.count
                                  : 0
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  class: [
                    "column column--width-auto mr-25 clickable",
                    {
                      "UserProfileAssignments__navigation--active":
                        _vm.currentSort === "verified"
                    }
                  ],
                  on: {
                    click: function($event) {
                      return _vm.navigate("verified")
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row row--align-center mb-8" }, [
                    _c("i", { staticClass: "fa fa-oval fa-10 fc-green mr-11" }),
                    _c(
                      "label",
                      {
                        class: [
                          "fs-14 fw-med",
                          { "fc-blue": _vm.currentSort === "verified" }
                        ]
                      },
                      [
                        _vm._v("\n          Verified\n          "),
                        _c("span", { staticClass: "fc-light fs-14" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.assignments.verified
                                  ? _vm.assignments.verified.count
                                  : 0
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  class: [
                    "column column--width-auto mr-25 clickable",
                    {
                      "UserProfileAssignments__navigation--active":
                        _vm.currentSort === "pending"
                    }
                  ],
                  on: {
                    click: function($event) {
                      return _vm.navigate("pending")
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row row--align-center mb-8" }, [
                    _c("i", {
                      staticClass: "fa fa-oval fa-10 fc-yellow mr-11"
                    }),
                    _c(
                      "label",
                      {
                        class: [
                          "fs-14 fw-med",
                          { "fc-blue": _vm.currentSort === "pending" }
                        ]
                      },
                      [
                        _vm._v("\n          In Progress\n          "),
                        _c("span", { staticClass: "fc-light fs-14" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.assignments.pending
                                  ? _vm.assignments.pending.count
                                  : 0
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  class: [
                    "column column--width-auto mr-25 clickable",
                    {
                      "UserProfileAssignments__navigation--active":
                        _vm.currentSort === "released"
                    }
                  ],
                  on: {
                    click: function($event) {
                      return _vm.navigate("released")
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row row--align-center mb-8" }, [
                    _c("i", { staticClass: "fa fa-oval fa-10 fc-red mr-11" }),
                    _c(
                      "label",
                      {
                        class: [
                          "fs-14 fw-med",
                          { "fc-blue": _vm.currentSort === "released" }
                        ]
                      },
                      [
                        _vm._v("\n          Released\n          "),
                        _c("span", { staticClass: "fc-light fs-14" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.assignments.released
                                  ? _vm.assignments.released.count
                                  : 0
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ])
                ]
              ),
              _vm.pagePOV === "client"
                ? _c("base-debtor-search", {
                    staticClass: "ml-auto mb-6",
                    attrs: {
                      "data-cy": "assignments-debtor-search-input",
                      "debtor-i-d-from-query": _vm.searchUserID,
                      label: false,
                      placeholder: "Search debtor"
                    },
                    on: { selected: _vm.searchByUser }
                  })
                : _vm._e(),
              _vm.pagePOV === "debtor"
                ? _c("base-client-search", {
                    staticClass: "ml-auto mb-6",
                    attrs: {
                      "client-i-d-from-query": _vm.searchUserID,
                      label: false,
                      placeholder: "Search client"
                    },
                    on: { selected: _vm.searchByUser }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.assignments[_vm.currentSort]
            ? _c(
                "div",
                { staticClass: "UserProfileAssignments__assignments column" },
                _vm._l(_vm.assignments[_vm.currentSort].rows, function(
                  assignment,
                  index
                ) {
                  return _c(
                    "div",
                    { key: index, staticClass: "column" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row row--align-center clickable mt-16 mb-16",
                          on: {
                            click: function($event) {
                              return _vm.expandAssignment(index)
                            }
                          }
                        },
                        [
                          _c("i", {
                            class: [
                              "fa fa-oval fa-10 mr-11",
                              {
                                "fc-red": assignment.status === "released",
                                "fc-green": assignment.status === "verified",
                                "fc-yellow": assignment.status === "pending"
                              }
                            ],
                            attrs: { "data-cy": "assignment-dot-" + index }
                          }),
                          _vm.pagePOV === "client"
                            ? _c(
                                "label",
                                {
                                  staticClass: "fs-15 uppercase",
                                  attrs: {
                                    "data-cy": "assignment-name-" + index
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.filterAlgoliaDebtorName(
                                          assignment.debtor.name
                                        )
                                      ) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.pagePOV === "debtor"
                            ? _c("label", { staticClass: "fs-15" }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(assignment.client.name) +
                                    "\n        "
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "transition",
                        {
                          attrs: {
                            mode: "out-in",
                            name: "Transition__opacity-fade"
                          }
                        },
                        [
                          assignment.expanded && assignment.history
                            ? _c(
                                "div",
                                {
                                  staticClass: "UserProfileAssignments__modal"
                                },
                                [
                                  assignment.history.length
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "UserProfileAssignments__modal-content"
                                          },
                                          _vm._l(assignment.history, function(
                                            history,
                                            historyIndex
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: historyIndex,
                                                staticClass:
                                                  "row row--align-center row--justify-between mb-3"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row row--align-center"
                                                  },
                                                  [
                                                    _c("i", {
                                                      class: [
                                                        "fa fa-oval fa-7 mr-11",
                                                        {
                                                          "fc-red":
                                                            history.status ===
                                                            "released",
                                                          "fc-green":
                                                            history.status ===
                                                            "verified",
                                                          "fc-yellow":
                                                            history.status ===
                                                            "pending"
                                                        }
                                                      ],
                                                      attrs: {
                                                        "data-cy":
                                                          "assignment-history-dot-" +
                                                          index +
                                                          "-" +
                                                          historyIndex
                                                      }
                                                    }),
                                                    _c(
                                                      "label",
                                                      {
                                                        class: [
                                                          "UserProfileAssignments__modal-content-status-lbl fs-13",
                                                          {
                                                            uppercase: [
                                                              "released",
                                                              "verified",
                                                              "pending"
                                                            ].includes(
                                                              history.status
                                                            )
                                                          }
                                                        ],
                                                        attrs: {
                                                          "data-cy":
                                                            "assignment-history-status-" +
                                                            index +
                                                            "-" +
                                                            historyIndex
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              history.status
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                history.email_type !== "noa"
                                                  ? _c(
                                                      "time",
                                                      {
                                                        staticClass:
                                                          "fc-mid fs-12 no-wrap",
                                                        attrs: {
                                                          datetime: _vm._f(
                                                            "datetime"
                                                          )(
                                                            history.created_at,
                                                            "YYYY-MM-DD hh:mm:ssZ"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "datetime"
                                                              )(
                                                                history.created_at,
                                                                "h:mm A MMM D, YYYY"
                                                              )
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                        _c("hr", { staticClass: "Divider" }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "UserProfileAssignments__footer-content row row--align-center bg"
                                          },
                                          [
                                            _c(
                                              "transition",
                                              {
                                                attrs: {
                                                  mode: "out-in",
                                                  name: "Transition__fade"
                                                }
                                              },
                                              [
                                                assignment.status ===
                                                  "released" && !_vm.emailing
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row row--align-center"
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "fs-14 no-wrap"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Release Letter"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row row--align-center row--justify-end"
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "mr-20",
                                                                  attrs: {
                                                                    "data-cy":
                                                                      "assignment-release-letter-" +
                                                                      index
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.viewReleaseLetter(
                                                                        assignment
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-view fa-17 fa--none fc-light"
                                                                  })
                                                                ]
                                                              ),
                                                              _c(
                                                                "button",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      _vm.showSendReleaseLetterFlow
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-mail fa-17 fa--none fc-light"
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : _vm.emailing
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row row--align-end"
                                                        },
                                                        [
                                                          _c(
                                                            "base-input",
                                                            {
                                                              staticClass:
                                                                "mr-10 width-100",
                                                              attrs: {
                                                                instructions:
                                                                  assignment.status ===
                                                                  "released"
                                                                    ? "Must be a email (@, domain name, ending required)"
                                                                    : _vm
                                                                        .instructions
                                                                        .NOA_EMAILS_INSTRUCTION,
                                                                label: true,
                                                                "label-for":
                                                                  "email",
                                                                valid:
                                                                  _vm.email
                                                                    .valid
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.email
                                                                    .value,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.email,
                                                                    "value",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "email.value"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      Email\n                    "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              class: [
                                                                "row row--width-auto",
                                                                {
                                                                  "mb-18": !_vm
                                                                    .email.valid
                                                                }
                                                              ]
                                                            },
                                                            [
                                                              _c(
                                                                "base-button",
                                                                {
                                                                  staticClass:
                                                                    "bg-blue fc-white mr-10",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.buttonsDisabled
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      assignment.status ===
                                                                      "released"
                                                                        ? _vm.sendReleaseLetter(
                                                                            assignment
                                                                          )
                                                                        : _vm.resendNOA(
                                                                            assignment,
                                                                            index
                                                                          )
                                                                    },
                                                                    keydown: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter",
                                                                          13,
                                                                          $event.key,
                                                                          "Enter"
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      assignment.status ===
                                                                      "released"
                                                                        ? _vm.sendReleaseLetter(
                                                                            assignment
                                                                          )
                                                                        : _vm.resendNOA(
                                                                            assignment,
                                                                            index
                                                                          )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        Submit\n                      "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "base-button",
                                                                {
                                                                  staticClass:
                                                                    "bg-light fc-white",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.buttonsDisabled
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.cancelEmailing,
                                                                    keydown: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter",
                                                                          13,
                                                                          $event.key,
                                                                          "Enter"
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      return _vm.cancelEmailing(
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        Cancel\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : [
                                                      _c(
                                                        "div",
                                                        { staticClass: "row" },
                                                        [
                                                          _c(
                                                            "base-button",
                                                            {
                                                              staticClass:
                                                                "bg-red fc-white",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.buttonsDisabled
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.releaseAssignment(
                                                                    assignment
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      Release Company\n                    "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "base-button",
                                                            {
                                                              staticClass:
                                                                "bg-blue fc-white ml-10",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.showResendNOAFlow(
                                                                    assignment
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      Email NOA\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "row ml-30 mt-16 mb-16"
                                          },
                                          [
                                            _vm._v(
                                              "\n              There is no historical data for this assignment\n            "
                                            )
                                          ]
                                        )
                                      ]
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          this.pagePOV === "client" &&
          _vm.searchUserID &&
          (!_vm.assignments.all || !_vm.assignments.all.rows.length)
            ? _c(
                "base-button",
                {
                  staticClass: "bg-blue fc-white ml-30 mb-20",
                  attrs: { "data-cy": "create-release-letter-btn" },
                  on: {
                    click: function($event) {
                      _vm.releasing = true
                    },
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.releasing = true
                    }
                  }
                },
                [_vm._v("\n    Create Release Letter\n  ")]
              )
            : _vm._e(),
          _c("base-pagination", {
            staticClass: "mb-30",
            attrs: {
              "item-count": _vm.assignments[_vm.currentSort]
                ? _vm.assignments[_vm.currentSort].count
                : 0,
              page: _vm.page,
              "request-limit": 25
            },
            on: {
              paginate: function($event) {
                _vm.page = $event
              }
            }
          }),
          _vm.releasing
            ? _c("base-item-delete", {
                attrs: {
                  "confirm-text": "Yes, Release Them",
                  "delete-text":
                    "Are you sure you want to create a release letter? It will create\n      a new assignment and set it to released"
                },
                on: {
                  cancel: function($event) {
                    _vm.releasing = false
                  },
                  delete: _vm.createReleaseAssignment
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }